<template>
  <div class="coupon_u">
    <div class="title">我的优惠券</div>
    <el-row class="input_" v-if="!show">
      <el-col :span="12">
        <div class="input">
          <el-input placeholder="请输入书籍标识码" v-model="book_num"></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="btn" @click="receive">
          一键领取优惠券
        </div>
      </el-col>
    </el-row>

    <div class="remarks" v-if="!show">
      注：标识码详见院校篇封面处，该标识码仅可使用一次
    </div>
    <div v-if="!show">
      <div class="img">
        <img src="@/assets/img/coupon/coupon_1.png" alt="">
        <div class="status">未领取</div>
      </div>

      <div class="img">
        <img src="@/assets/img/coupon/coupon_2.png" alt="">
        <div class="status">未领取</div>
      </div>
    </div>
    <div v-else>
      <div class="img" v-for=" (item,i) in dataList" :key="i">

        <img src="@/assets/img/coupon/coupon_1.png" alt="" v-if="item.coupon_id == 1">
        <img src="@/assets/img/coupon/coupon_2.png" alt="" v-if="item.coupon_id == 2">
        <div class="status" v-if="item.status == 0">未使用</div>
        <div class="status" v-if="item.status == 1">已使用</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      book_num: "",
      show: false,
      dataList: [],
    };
  },
  created() {
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      this.$fecth.post("coupon/getCoupon").then((res) => {
        if (res.length > 0) {
          this.dataList = res;
          this.show = true;
        } else {
          this.show = false;
        }
      });
    },
    receive() {
      let data = {
        key: this.book_num,
      };
      this.$fecth.post("coupon/receiveCoupon", data).then((res) => {
        if (res) {
          this.$notify({
            showClose: true,
            message: "恭喜你，领取成功！",
            type: "success",
          });
          setTimeout(() => {
            this.getCoupon();
          }, 1000);
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.coupon_u {
  background: white;
  min-height: 550px;
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
  .input_ {
    margin-top: 25px;
  }
  overflow: hidden;
  .input {
    width: 400px;
    margin: 0 auto;
  }
  .btn {
    width: 195px;
    height: 37px;
    margin: 0 auto;
    text-align: center;
    line-height: 37px;
    color: white;
    background: url("../../../assets/img/coupon/coupon_btn.png");
  }
  .remarks {
    margin-left: 50px;
    margin-top: 20px;
    color: rgb(102, 102, 102);
  }
  .img {
    margin-left: 50px;
    margin-top: 20px;
    display: inline-block;
    position: relative;
    .status {
      position: absolute;
      top: 10px;
      right: 3px;
      width: 29px;
      color: rgb(192, 190, 190);
    }
  }
}
</style>
